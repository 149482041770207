
import { defineComponent } from 'vue';
import { ClipboardIcon } from '@zhuowenli/vue-feather-icons';

export default defineComponent({
  components: {
    ClipboardIcon,
  },
  data: () => ({
    status: 'loading' as 'loading' | 'loaded' | 'error',
    signUpUrl: '',
    sentInvitations: [] as Array<{
      // eslint-disable-next-line camelcase
      to_email: string;
      // eslint-disable-next-line camelcase
      to_phone: string;
      created: string;
      status: string;
    }>,
    sendFormFields: {
      to: '',
    },
    sendFormErrors: {} as Record<string, Array<string>>,
    sendFormSubmitting: false,
    sendFormSuccess: false,
    resendFormSubmitting: [] as Array<number>,
    resendFormSuccess: [] as Array<number>,
  }),
  watch: {
    sendFormErrors() {
      if (!this.isObjectEmpty(this.sendFormErrors)) {
        this.$nextTick(this.scrollToFirstError);
      }
    },
  },
  created() {
    this.signUpUrl = `https://www.theplayersarena.com${this.$router.resolve({ name: 'signup', params: { username: this.userData.username } }).href}`;
    this.loadSentInvitations();
  },
  methods: {
    selectSignUpUrlInput() {
      (this.$refs.signUpUrlInput as HTMLInputElement).setSelectionRange(0, this.signUpUrl.length);
    },
    copySignUpUrl() {
      navigator.clipboard.writeText(this.signUpUrl).then(() => {
        this.$swal.fire({
          title: 'Link copied!',
          icon: 'success',
          width: '17rem',
          toast: true,
          position: 'bottom',
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
      });
    },
    async loadSentInvitations() {
      this.status = 'loading';

      const responseData = await this.api({ url: 'business-portal/invitations/' });

      if (responseData.status === 200) {
        this.sentInvitations = responseData.body;
        this.status = 'loaded';
      } else {
        this.status = 'error';
      }
    },
    async submitSendForm() {
      this.sendFormErrors = {};
      this.sendFormSubmitting = true;

      const responseData = await this.api({
        url: 'business-portal/invitations/',
        method: 'POST',
        json: this.sendFormFields,
      });

      this.sendFormSubmitting = false;

      if (responseData.status === 201) {
        this.sendFormSuccess = true;
        this.sendFormFields.to = '';
        this.sentInvitations.unshift(responseData.body);
      } else if (responseData.status === 400) {
        this.sendFormErrors = responseData.body;
      } else {
        this.sendFormErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
    async submitResendForm(index: number) {
      this.resendFormSubmitting.push(index);

      let resend;

      if (this.sentInvitations[index].to_email !== '') {
        resend = this.sentInvitations[index].to_email;
      } else {
        resend = this.sentInvitations[index].to_phone;
      }

      const responseData = await this.api({
        url: 'business-portal/invitations/',
        method: 'POST',
        json: { resend },
      });

      this.resendFormSubmitting = this.resendFormSubmitting.filter((i) => i !== index);

      if (responseData.status === 200) {
        this.resendFormSuccess.push(index);
      } else {
        let text;

        if (responseData.status === 400) {
          text = responseData.body.detail;

          if (responseData.body.new_status) {
            this.sentInvitations[index].status = responseData.body.new_status;
          }
        } else {
          text = 'Please check your connection and try again.';
        }

        this.$swal('Failed to Resend Invitation', text);
      }
    },
  },
});
