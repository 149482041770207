<template>
  <div class="horizontal-padding">
    <h1>Invitations</h1>

    <spinner
      v-if="status === 'loading'"
      preset="large"
    />

    <template v-else-if="status === 'error'">
      <alert variant="danger">
        An error occurred loading your invitations. Please check your
        connection and try again.
      </alert>

      <button
        type="button"
        class="btn btn-outline-primary"
        @click="loadSentInvitations"
      >
        Try Again
      </button>
    </template>

    <template v-else-if="status === 'loaded'">
      <div class="top-content">
        <p>Share this link with others on social media, email, etc.:</p>

        <div class="form-group">
          <input
            ref="signUpUrlInput"
            type="text"
            readonly
            :value="signUpUrl"
            @click="selectSignUpUrlInput"
          >
          <button
            type="button"
            class="btn btn-primary btn-sm"
            @click="copySignUpUrl"
          >
            <clipboard-icon />
          </button>
        </div>

        <p>
          You may also use the form below to send invitations to specific people.
          This will allow you to track whether or not they visited your
          invitation link and whether or not they signed up.
        </p>
      </div>

      <div class="new-invitation-content">
        <template v-if="sendFormSuccess">
          <alert variant="success">
            Your invitation has been sent!
          </alert>

          <button
            type="button"
            class="btn btn-outline-primary"
            @click="sendFormSuccess = false"
          >
            Send Another
          </button>
        </template>

        <template v-else>
          <alert
            v-if="sendFormErrors.non_field_errors !== undefined"
            variant="danger"
            class="js-form-error"
            dismissible
            @dismissed="sendFormErrors = {}"
          >
            {{ sendFormErrors.non_field_errors[0] }}
          </alert>

          <form @submit.prevent="submitSendForm">
            <div class="form-group">
              <label for="to">Email address or phone number to send the invitation to</label>
              <input
                id="to"
                v-model="sendFormFields.to"
                :class="{ 'is-invalid': sendFormErrors.to !== undefined }"
                type="text"
                maxlength="254"
                required
              >
              <div
                v-if="sendFormErrors.to !== undefined"
                class="invalid-feedback js-form-error"
              >
                {{ sendFormErrors.to[0] }}
              </div>
            </div>

            <button
              type="submit"
              class="btn btn-primary"
              :disabled="sendFormSubmitting"
            >
              <template v-if="sendFormSubmitting">
                Sending Invitation
                <spinner />
              </template>

              <template v-else>
                Send Invitation
              </template>
            </button>
          </form>
        </template>
      </div>

      <h2>Sent Invitations</h2>

      <table v-if="sentInvitations.length">
        <colgroup>
          <col class="col-sent-on">
          <col>
          <col class="col-status">
        </colgroup>
        <thead>
          <tr>
            <th>Sent on</th>
            <th>Sent to</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(invitation, index) of sentInvitations"
            :key="`invitation${index}`"
          >
            <td>{{ timestampDisplay(invitation.created) }}</td>
            <td>
              <template v-if="invitation.to_email !== ''">
                {{ invitation.to_email }}
              </template>

              <template v-else>
                {{ invitation.to_phone }}
              </template>
            </td>
            <td>
              <template v-if="invitation.status.startsWith('Signed up as')">
                <span>Signed up</span>

                <router-link
                  :to="{ name: 'user_profile', params: { username: invitation.status.slice(13) } }"
                >
                  View profile
                </router-link>
              </template>

              <template v-else>
                {{ invitation.status }}

                <template v-if="invitation.status.startsWith('Not signed up')">
                  <div
                    v-if="resendFormSuccess.includes(index)"
                    class="green-text"
                  >
                    Invitation resent!
                  </div>

                  <form
                    v-else
                    @submit.prevent="submitResendForm(index)"
                  >
                    <button
                      type="submit"
                      class="btn btn-outline-primary btn-sm"
                      :disabled="resendFormSubmitting.includes(index)"
                    >
                      <template v-if="resendFormSubmitting.includes(index)">
                        Resending Invitation
                        <spinner color="var(--blue)" />
                      </template>

                      <template v-else>
                        Resend Invitation
                      </template>
                    </button>
                  </form>
                </template>
              </template>
            </td>
          </tr>
        </tbody>
      </table>

      <p
        v-else
        class="text-center"
      >
        You have not sent any invitations yet.
      </p>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ClipboardIcon } from '@zhuowenli/vue-feather-icons';

export default defineComponent({
  components: {
    ClipboardIcon,
  },
  data: () => ({
    status: 'loading' as 'loading' | 'loaded' | 'error',
    signUpUrl: '',
    sentInvitations: [] as Array<{
      // eslint-disable-next-line camelcase
      to_email: string;
      // eslint-disable-next-line camelcase
      to_phone: string;
      created: string;
      status: string;
    }>,
    sendFormFields: {
      to: '',
    },
    sendFormErrors: {} as Record<string, Array<string>>,
    sendFormSubmitting: false,
    sendFormSuccess: false,
    resendFormSubmitting: [] as Array<number>,
    resendFormSuccess: [] as Array<number>,
  }),
  watch: {
    sendFormErrors() {
      if (!this.isObjectEmpty(this.sendFormErrors)) {
        this.$nextTick(this.scrollToFirstError);
      }
    },
  },
  created() {
    this.signUpUrl = `https://www.theplayersarena.com${this.$router.resolve({ name: 'signup', params: { username: this.userData.username } }).href}`;
    this.loadSentInvitations();
  },
  methods: {
    selectSignUpUrlInput() {
      (this.$refs.signUpUrlInput as HTMLInputElement).setSelectionRange(0, this.signUpUrl.length);
    },
    copySignUpUrl() {
      navigator.clipboard.writeText(this.signUpUrl).then(() => {
        this.$swal.fire({
          title: 'Link copied!',
          icon: 'success',
          width: '17rem',
          toast: true,
          position: 'bottom',
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
        });
      });
    },
    async loadSentInvitations() {
      this.status = 'loading';

      const responseData = await this.api({ url: 'business-portal/invitations/' });

      if (responseData.status === 200) {
        this.sentInvitations = responseData.body;
        this.status = 'loaded';
      } else {
        this.status = 'error';
      }
    },
    async submitSendForm() {
      this.sendFormErrors = {};
      this.sendFormSubmitting = true;

      const responseData = await this.api({
        url: 'business-portal/invitations/',
        method: 'POST',
        json: this.sendFormFields,
      });

      this.sendFormSubmitting = false;

      if (responseData.status === 201) {
        this.sendFormSuccess = true;
        this.sendFormFields.to = '';
        this.sentInvitations.unshift(responseData.body);
      } else if (responseData.status === 400) {
        this.sendFormErrors = responseData.body;
      } else {
        this.sendFormErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
    async submitResendForm(index: number) {
      this.resendFormSubmitting.push(index);

      let resend;

      if (this.sentInvitations[index].to_email !== '') {
        resend = this.sentInvitations[index].to_email;
      } else {
        resend = this.sentInvitations[index].to_phone;
      }

      const responseData = await this.api({
        url: 'business-portal/invitations/',
        method: 'POST',
        json: { resend },
      });

      this.resendFormSubmitting = this.resendFormSubmitting.filter((i) => i !== index);

      if (responseData.status === 200) {
        this.resendFormSuccess.push(index);
      } else {
        let text;

        if (responseData.status === 400) {
          text = responseData.body.detail;

          if (responseData.body.new_status) {
            this.sentInvitations[index].status = responseData.body.new_status;
          }
        } else {
          text = 'Please check your connection and try again.';
        }

        this.$swal('Failed to Resend Invitation', text);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
  .top-content {
    margin: 0 auto;
    width: min(100%, 60em);

    .form-group {
      display: grid;
      grid-template-columns: 1fr auto;
    }
  }

  .new-invitation-content {
    margin: 0 auto 1rem;
    width: 290px;
  }

  table {
    margin: 0 auto;
    border-collapse: collapse;
    table-layout: fixed;
    width: min(100%, 60em);
  }

  .col-sent-on {
    width: 6em;
  }

  .col-status {
    width: 8em;
  }

  tbody tr:nth-child(odd) {
    background-color: var(--gray-darker);
  }

  th,
  td {
    padding: 0.25rem;
  }

  td {
    vertical-align: top;
    word-wrap: break-word;

    a {
      font-size: 0.7rem;
      color: var(--blue);
    }
  }

  @media (max-width: 639.98px) {
    span {
      display: block;
    }
  }

  @media (min-width: 640px) {
    .col-sent-on {
      width: 21em;
    }

    .col-status {
      width: 13em;
    }

    span {
      margin-right: 0.5em;
    }
  }
</style>
